import React from 'react'
import { useRive } from '@rive-app/react-canvas';

const RiveAnimation = (props) => {

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const { rive, RiveComponent } = useRive({
        src: props.src,
        stateMachines: props.stateMachineName,
        autoplay: !isMobile,
    })

    return (
        <RiveComponent className={props.customClass}/>
    )
}

export default RiveAnimation;