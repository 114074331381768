import React, { useEffect, useState } from "react";

const CategoriesMenu = (props) => {
  const {
    categories: initialCategories,
    pins,
    setProductsPopupID,
    setProductsList,
    setCurrentNodeProductsTitle,
    setInCategMenu,
    selectedAreaBuilding,
    setSelectedAreaBuilding,
    translations,
    previousPage,
    currentNodeTitle,
    currentNode,
  } = props;
  // console.log("CategoriesMenu", initialCategories);
  // console.log("selectedAreaBuilding", selectedAreaBuilding);

  const lang = localStorage.getItem("selectedLanguage") || "en";

  const [categOpen, setCategOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [secondLevelItems, setSecondLevelItems] = useState([]);
  const [categories, setCategories] = useState(initialCategories);
  const [currentLevel, setCurrentLevel] = useState(0);

  // console.log("currentLevel", currentLevel);

  useEffect(() => {
    if (currentNode) {
      const level = currentNode.split("-").length - 1;
      setCurrentLevel(level);

      if (level === 0) {
        setSelectedAreaBuilding({
          areaID: null,
          buildingID: null,
        });
      }
    }
  }, [currentNode, setCurrentLevel, setSelectedAreaBuilding]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        event.target.closest(".categoriesMenuWrapper") === null &&
        event.target.closest(".categoryButton") === null &&
        event.target.closest(".productsPopup") === null
      ) {
        setCategOpen(false);
        setSelectedCategory(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedAreaBuilding.areaID !== null) {
      const newCategory = initialCategories
        .map((cat) => {
          const filteredCatLocs = cat.catLocations.filter((catLoc) => {
            return selectedAreaBuilding.buildingID === null
              ? selectedAreaBuilding.areaID &&
                  catLoc.areaID === selectedAreaBuilding.areaID
              : selectedAreaBuilding.areaID &&
                  catLoc.areaID === selectedAreaBuilding.areaID &&
                  selectedAreaBuilding.buildingID &&
                  catLoc.buildingID === selectedAreaBuilding.buildingID;
          });
          return filteredCatLocs.length > 0
            ? { ...cat, catLocations: filteredCatLocs }
            : null;
        })
        .filter((cat) => cat !== null);
      // console.log("newCategory", newCategory);
      setCategories(newCategory);
    } else {
      setCategories(initialCategories);
    }
  }, [initialCategories, selectedAreaBuilding, currentNode]);

  const categoryClickHandler = (categId) => {
    setSelectedCategory(categId);
    const category = categories.find((cat) => cat.objectid === categId);

    if (!category) {
      setSecondLevelItems([]);
      return;
    }

    const linesSet = new Set();
    const linesIds = [];

    category.catLocations.forEach((catLoc) => {
      const line = catLoc.productLine.toLowerCase().replace(/\s+/g, "");
      linesSet.add(line);
      linesIds[line] = linesIds[line] || [];
      linesIds[line].push({
        areaID: catLoc.areaID,
        buildingID: catLoc.buildingID,
        productLineID: catLoc.productLineID,
      });
    });
    // console.log("linesIds", linesIds);

    const linesArray = Array.from(linesSet).map((line) => {
      const filteredLines = category.catLocations.find(
        (catLoc) =>
          catLoc.productLine.toLowerCase().replace(/\s+/g, "") === line
      );
      const catLoc = filteredLines.productLine;
      const catLocAr = filteredLines.productLineAr;
      return {
        catLoc: {
          en: catLoc,
          ar: catLocAr,
        },
        linesIds: linesIds[line],
      };
    });

    // console.log("linesArray", linesArray);

    setSecondLevelItems(linesArray);
  };

  const productLineClickHandler = (item) => {
    // console.log("item", item);
    // const { areaID, buildingID, productLineID } = productLine;
    const prods = [];
    const selectedCountry = localStorage.getItem("selectedCountryValue");
    // console.log("selectedCountry", selectedCountry);

    item.linesIds.forEach((lineId) => {
      const { areaID, buildingID, productLineID } = lineId;
      const prod = pins
        .find((pin) => pin.objectid === areaID)
        .pins.find((pin) => pin.objectid === buildingID)
        .pins.find((pin) => pin.objectid === productLineID)?.products;
      // console.log("prod", prod);

      const filteredByCountryProd = prod?.filter((p) =>
        p.countries.find((c) =>
          c.value === "All GCC" ? true : c.value === selectedCountry
        )
      );

      if (filteredByCountryProd) {
        prods.push(...filteredByCountryProd);
        const uniqueProds = Array.from(new Set(prods.map((p) => p.sku))).map(
          (sku) => prods.find((p) => p.sku === sku)
        );
        prods.length = 0;
        prods.push(...uniqueProds);
      }
    });

    setInCategMenu(true);
    setProductsPopupID("123");
    setProductsList(prods);
    setCurrentNodeProductsTitle(
      lang === "en" ? item.catLoc.en : item.catLoc.ar
    );

    // console.log("prods", prods);
  };

  return (
    <div
      className={`categoriesMenuWrapper ${categOpen ? "open" : ""} ${
        selectedCategory ? "selectedCategory" : ""
      }`}
      dir={lang === "ar" ? "rtl" : "ltr"}
    >
      <button
        className="categoryButton"
        type="button"
        onClick={() => {
          setCategOpen(!categOpen);
        }}
      >
        <div className="categImg">
          <svg
            width="31"
            height="26"
            viewBox="0 0 31 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.4703 12.9C15.3219 12.9644 15.162 12.9977 15.0003 12.9977C14.8386 12.9977 14.6786 12.9644 14.5303 12.9L8.83027 10.26C8.73771 10.2133 8.65993 10.1419 8.60558 10.0536C8.55124 9.96532 8.52246 9.86369 8.52246 9.76002C8.52246 9.65636 8.55124 9.55472 8.60558 9.46645C8.65993 9.37817 8.73771 9.30671 8.83027 9.26002L14.5303 6.60002C14.6786 6.53562 14.8386 6.50238 15.0003 6.50238C15.162 6.50238 15.3219 6.53562 15.4703 6.60002L21.1703 9.24002C21.2628 9.28671 21.3406 9.35817 21.395 9.44645C21.4493 9.53472 21.4781 9.63636 21.4781 9.74002C21.4781 9.84369 21.4493 9.94532 21.395 10.0336C21.3406 10.1219 21.2628 10.1933 21.1703 10.24L15.4703 12.9Z"
              stroke="#002B62"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.5 13.35L15.4 16.16C15.2697 16.2195 15.1282 16.2502 14.985 16.2502C14.8418 16.2502 14.7003 16.2195 14.57 16.16L8.5 13.35"
              stroke="#002B62"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.5 16.6L15.4 19.41C15.2697 19.4695 15.1282 19.5002 14.985 19.5002C14.8418 19.5002 14.7003 19.4695 14.57 19.41L8.5 16.6"
              stroke="#002B62"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <span>
          {lang === "en"
            ? translations?.shopbyCategory || "Shop by Category"
            : translations?.shopbyCategoryAr || "تسوق حسب الفئة"}
        </span>
      </button>
      <div className={`categoriesMenu`}>
        <div className="menuHeader">
          <button
            className="closeMenu"
            type="button"
            onClick={() => {
              setCategOpen(false);
                setSelectedCategory(null);
              }}
              ></button>
              <h2
              dangerouslySetInnerHTML={{
                __html: previousPage
                ? `${
                  lang === "en"
                    ? translations?.shopbyCategory || "Shop by Category in"
                    : translations?.shopbyCategoryInAr || "تسوق حسب الفئة في"
                  } <span>${currentLevel === 1 ? "" : previousPage} ${
                  currentNodeTitle
                    ? (currentLevel === 1 ? "" : " > ") + currentNodeTitle
                    : ""
                  }</span>`
                : lang === "en"
                ? translations?.shopbyCategory || "Shop by Category"
                : translations?.shopbyCategoryAr || "تسوق حسب الفئة",
              }}
              ></h2>
            </div>
            <div
              className={`menuBody ${selectedCategory ? "selectedCategory" : ""}`}
            >
              <ul className="firstLevelItems">
              {categories?.map((category) => {
              return (
                <li
                  key={category.objectid}
                  onClick={() => categoryClickHandler(category.objectid)}
                  className={`${
                    selectedCategory === category.objectid ? "selected" : ""
                  }`}
                >
                  <img src={category.icon ?? "/dummy-categ.svg"} alt="icon" />
                  <p>{lang === "en" ? category.title : category.titleAr}</p>
                </li>
              );
            })}
          </ul>
          <ul className="secondLevelItems">
            <button
              onClick={() => {
                setSelectedCategory(null);
                setSecondLevelItems([]);
              }}
              className="backbtn"
            >
              {localStorage.getItem("selectedLanguage") === "ar"
                ? translations?.backToAr
                : translations?.backTo}{" "}
              {selectedCategory
                ? lang === "en"
                  ? categories.find((cat) => cat.objectid === selectedCategory)
                      .title
                  : categories.find((cat) => cat.objectid === selectedCategory)
                      .titleAr
                : ""}
            </button>

            {secondLevelItems.map((item, index) => {
              return (
                <li key={index} onClick={() => productLineClickHandler(item)}>
                  <p>{lang === "en" ? item.catLoc.en : item.catLoc.ar}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CategoriesMenu;
