export class IndexedDBManager {
  constructor(dbName = "appDatabase", storeName = "appData", version = 1) {
    this.dbName = dbName;
    this.version = version;
    this.storeName = storeName;
  }

  async initDB() {
    try {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open(this.dbName, this.version);

        request.onerror = () =>
          reject(new Error(`Failed to open database: ${request.error}`));

        request.onupgradeneeded = (event) => {
          const db = event.target.result;
          if (!db.objectStoreNames.contains(this.storeName)) {
            const store = db.createObjectStore(this.storeName, {
              keyPath: "id",
            });
            store.createIndex("dateSaved", "dateSaved", { unique: false });
          }
        };

        request.onsuccess = () => resolve(request.result);
      });
    } catch (error) {
      throw new Error(`Database initialization failed: ${error.message}`);
    }
  }

  async saveData(id, data) {
    if (!id || !data) throw new Error("ID and data are required");

    try {
      const db = await this.initDB();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction([this.storeName], "readwrite");
        const store = transaction.objectStore(this.storeName);

        const record = {
          id,
          dateSaved: new Date().toISOString(),
          data,
        };

        const request = store.put(record);

        request.onsuccess = () => {
          resolve(record);
          this.closeConnection(db);
        };
        request.onerror = () => {
          reject(new Error(`Save operation failed: ${request.error}`));
          this.closeConnection(db);
        };
      });
    } catch (error) {
      throw new Error(`Save operation failed: ${error.message}`);
    }
  }

  async getData(id) {
    if (!id) throw new Error("ID is required");

    try {
      const db = await this.initDB();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction([this.storeName], "readonly");
        const store = transaction.objectStore(this.storeName);
        const request = store.get(id);

        request.onsuccess = () => {
          resolve(request.result);
          this.closeConnection(db);
        };
        request.onerror = () => {
          reject(new Error(`Fetch operation failed: ${request.error}`));
          this.closeConnection(db);
        };
      });
    } catch (error) {
      throw new Error(`Fetch operation failed: ${error.message}`);
    }
  }

  async deleteData(id) {
    if (!id) throw new Error("ID is required");
    console.log("request delete");

    try {
      const db = await this.initDB();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction([this.storeName], "readwrite");
        const store = transaction.objectStore(this.storeName);
        const request = store.delete(id);
        

        request.onsuccess = () => {
          resolve(true);
          this.closeConnection(db);
        };
        request.onerror = () => {
          reject(new Error(`Delete operation failed: ${request.error}`));
          this.closeConnection(db);
        };
      });
    } catch (error) {
      throw new Error(`Delete operation failed: ${error.message}`);
    }
  }

  closeConnection(db) {
    if (db && db.close) {
      db.close();
    }
  }
}