import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Password from "./components/Password";

const urlLanguage = new URLSearchParams(window.location.search).get("language");
const localStorageLanguage = localStorage.getItem("selectedLanguage");
const language = urlLanguage || localStorageLanguage;

const Main = () => {
  const [isUnlocked, setIsUnlocked] = useState(false);
  const isRunningInIframe = window.self !== window.top;
  const matchedDocumentReferrer =
    document.referrer == "https://woosooqconnect.com/" ||
    document.referrer == "https://www.woosooqconnect.com/" ||
    document.referrer == "https://woosooq.com/" ||
    document.referrer == "https://www.woosooq.com/" ||
    document.referrer == "https://www.sa.woosooq.com/" ||
    document.referrer == "https://www.bh.woosooq.com/" ||
    document.referrer == "https://www.ae.woosooq.com/" ||
    document.referrer == "https://sa.woosooq.com/" ||
    document.referrer == "https://bh.woosooq.com/" ||
    document.referrer == "https://ae.woosooq.com/";

  // const testLink = "https://l7820113c1dev-store.occa.ocs.oraclecloud.com/home"
  // const liveLink = "https://l7820113c1dev-store.occa.ocs.oraclecloud.com/"

  useEffect(() => {
    // alert();
    // console.log(
    //   "document.referrer= ",
    //   document.referrer,
    //   document.referrer == "https://l7820113c1dev-store.occa.ocs.oraclecloud.com/"
    // );
    // console.log("isRunningInIframe =", isRunningInIframe);

    if (isRunningInIframe && matchedDocumentReferrer) {
      // console.log("unlocked!");
      setIsUnlocked(true);
    } else {
      // console.log("locked!");
      if (!isRunningInIframe && matchedDocumentReferrer) {
        window.location.href = "https://b2bgate.napconational.com/";
      }
      setIsUnlocked(false);
    }
  }, []);

  const handleUnlock = () => {
    setIsUnlocked(true);
  };

  return isUnlocked ? (
    <App matchedDocumentReferrer={matchedDocumentReferrer} />
  ) : (
    <Password onUnlock={handleUnlock} />
  );
  // <App />
};

if (language === "ar") {
  import("./index-ar.scss").then(() => {
    localStorage.setItem("selectedLanguage", "ar");

    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(<Main />);
  });
} else {
  localStorage.setItem("selectedLanguage", "en");

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<Main />);

  // Register the service worker
  // serviceWorkerRegistration.register();
}
